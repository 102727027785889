.BlogCategoryShow {
    &-main {
        padding-top: @pad-md-tb;
        padding-bottom: @pad-md-tb;
        background: @w-100;
    }

    &-grid {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        margin: 0;
        &-col {
           padding: 0; 
        }
    }

    @media screen and (min-width: 992px) {
        &-grid {
            flex-direction: row;
            margin: -(@space*0.75);
            &-col {
                padding: @space*0.75;
                &--articles {
                    max-width: 66.66%;
                    flex-basis: 66.66%;
                }

                &--cat {
                    max-width: 33.33%;
                    flex-basis: 33.33%;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) {
        &-grid {
            margin: -(@space);
            &-col {
                padding: @space;
            }
        }
    }
}

.Pagination {
    .pagination {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: @space*2;
        padding-left: 0;
 
        li {
            margin-top: @space/2;
            margin-bottom: @space/2;
            margin-left: 0;
            margin-right: 0;
            list-style: none;

            * {
                padding: @space/2 @space*0.66;
            }

            a {
                font-family: @font-family-heading;
                color: @bl-100;
                background-color: @lgy-080;
                transition: @bezier;

                &:hover {
                    color: @w-100;
                    background-color: @bl-100;
                }
            }

            &.active > * {
                color: @w-100;
                background-color: @bl-100;
            }

            &.disabled > * {
                color: @w-100;
                background-color: @w-100;
            }
        }
    }
}