//  Menu
@import './menu/show/menuShowMain.less';
@import './menu/show/menuShowSide.less';

//  Static
@import './static/wfuk/cmsStaticWfuk.less';
@import './static/test/cmsStaticTest.less';

// Sitemap
@import './sitemap/show/cmsSitemapShow.less';



// Base template styles

.CmsBase {
    &-main {
        padding-top: @space / 2;
        padding-bottom: @space * 2;
    }
}
