.MenuMain {
    position: relative;
    width: 100%;
    &-root {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
        > * + * {
            margin-left: calc(~"35px + (45 - 35) * ( (100vw - 1024px) / ( 1920 - 1023) )");
        }
    }

    &-item {
        position: relative;
        display: flex;
        margin-bottom: 0;
        &-link {
            position: relative;
            display: block;
            width: 100%;
            padding-top: 1px;
            padding-bottom: 1px;
            font-family: @font-family-heading;
            font-size: calc(~"15px + (16 - 15) * ( (100vw - 1024px) / ( 1920 - 1023) )");
            font-weight: 600;
            text-transform: uppercase;
            color: @bl-100;
            cursor: pointer;
        }
        

        &.is-active > a {
            span {
                color: @rd-100;
                &:after {
                    width: 100%;
                    background-color: @rd-100;
                }
            }
        }

        &.is-top {
            display: block;
        }

        &.is-top.is-active > a {
            color: @rd-100;
        }

        &.has-sub {
            > .MenuMain-item-link {
                &:after {
                    content: '\f107';
                    font-family: 'FontAwesome';
                    margin-left: 5px;
                }
            }
        }
    }

    &-toggle {
        display: none;
    }

    &-hamburger {
        position: relative;
        display: block;
        width: 40px;
        height: @space;
        margin: 0px;
        cursor: pointer;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        z-index: 9999;
        &-line {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: @bl-100;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .15s ease-in-out;
        }
    }

    &-dd {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: auto;
        min-width: 275px;
        padding: @space/2 0;
        text-align: left;
        background: @w-100;
        box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
        z-index: 9999;
        &-toggle {
            display: none;
        }
    }

    &-dd & {
        &-item {
            width: 100%;
            &-link {
                padding: @space/3 @space;
            }
        }
    }

    @media screen and (min-width: 1920px) {
        &-root {
            > * + * {
                margin-left: @space*1.5;
            }
        }

        &-item {
            &-link {
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -100%;
        right: 0;
        width: 100%;
        height: 100%;
        background: @w-100;
        opacity: 0;
        transition: all .3s cubic-bezier(.23,1,.32,1);
        z-index: 9998;
        &.active {
            display: block;
            left: 0;
            opacity: 1;
        }

        &.active & {
            &-item {
                opacity: 1;
                transition-delay: 0.3s;
            }
        }

        &-root {
            display: block;
            padding: calc(~"75px + (120 - 75) * ( (100vw - 320px) / ( 1024 - 320) )") @space/2 @space;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            > * + * {
                margin-left: 0;
                border-top: solid 1px @lgy-080;
            }
        }

        &-item {
            overflow: hidden;
            &-link {
                padding-top: @space/2;
                padding-bottom: @space/2;
                padding-left: @space*0.66;
                font-size: @font-size;
                transition: none;
                &:focus, &:hover {
                    color: @rd-100;
                }
            }

            &.is-top {
                transition: @bezier;
                opacity: 0;
            }

            &.has-sub {
                > .MenuMain-item-link {
                    &:after {
                        display: none;
                    }
                }
            }

            &.is-open {
                .MenuMain-item-link, .MenuMain-dd {
                    background: @lgy-080;
                }

                a.MenuMain-dd-toggle svg {
                    color: @rd-100;
                    transform: rotate(0deg);
                }
            }
        }

        &-toggle {
            display: block;
        }

        &-hamburger {
            &-line {
                &:nth-child(1) { top: 3px; }
                &:nth-child(2) { top: 14px; }
                &:nth-child(3) { top: 14px; }
                &:nth-child(4) { top: 25px; }
            }
            
            .is-open & {
                &-line {
                    color: @bl-100;
                    &:nth-of-type(1), &:nth-of-type(4) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }

                    &:nth-of-type(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-of-type(3) {
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        &-dd {
            position: relative;
            display: none;
            width: auto;
            padding: 0 0 0 @space*0.66;
            box-shadow: none;
            opacity: 1;
            visibility: visible;
            transition: none;
            &-toggle {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                padding: 0;
                width: 100%;
                height: 53px;
                z-index: 9999;
                transition: @bezier;
                svg {
                    position: absolute;
                    margin: auto;
                    top: 22%;
                    right: @space/2;
                    width: @space;
                    height: @space;
                    transform: rotate(45deg);
                    transition: @bezier;
                }
            }
        }

        &-dd & {
            &-item {
                &-link {
                    padding: @space/2 @space*0.66;
                }
            }
        }
    }
}

.Menu {
    &--footerTop, &--footerBtm {
        ul, li {
            padding: 0;
            list-style: none;
        }

        .MenuMain {
            &-item {
                &-link {
                    margin-right: @space/3;
                    padding: 0;
                    font-size: @font-size;

                    @media screen and (min-width: 320px) {
                        font-size: calc(~"16px + (18 - 16) * ( (100vw - 320px) / ( 1024 - 320) )");
                    }

                    @media screen and (min-width: @screen-md) {
                        font-size: calc(~"15px + (18 - 15) * ( (100vw - 1024px) / ( 1550 - 1024) )");
                    }

                    @media screen and (min-width: @screen-lg) {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    &--footerTop & {
        &-root {
            > * + * {
                margin-top: @space/3;
            }
        }
    }

    &--footerBtm {
        margin-bottom: @space*0.66;
    }

    &--footerBtm & {
            margin-bottom: @space*0.66;
            &-root {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .MenuMain {
                    &-item {
                        &-link {
                            font-family: @font-family;
                            font-size: 14px;
                            span {
                                font-weight: 400;
                            }

                            &:hover {
                                span {
                                    color: @w-100;
                                }
                            }
                        }

                        &.is-active > a {
                            span {
                                color: @w-100;
                                &:after {
                                    width: 100%;
                                    background-color: @w-100;
                                }
                            }
                        }
                    }
                }
            }
        }

    @media screen and (min-width: @screen-md) {
        &--footerBtm {
            .MenuMain {
                &-item {
                    &-link {
                        margin-right: calc(~"5px + (30 - 5) * ( (100vw - 1001px) / ( 1920 - 1001) )");
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1920px) {
        &--footerBtm {
            .MenuMain {
                &-item {
                    &-link {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}