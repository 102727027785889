.ArticleExcerpt {
    position: relative;
    display: block;
    background-color: @w-100;
    &:focus, &:hover {
        .ArticleExcerpt {
            &-cat {
                &-wrap {
                    span {
                        &:after {
                            width: 0;
                        }
                    }
                }
            }
        }
    }
        
    &:hover & {
        &-img {
            transform: scale(1.01);
        }
    }

    &-img {
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: @bezier;
        &-wrap {
            position: relative;
            overflow: hidden;
        }
    }

    &-content {
        &-wrap {
            position: relative;
            padding: @space*0.66 0 0;
        }
    }

    &-title {
        margin-bottom: 0;
        font-weight: 600;
        color: @bl-100;
        transition: @bezier;
    }

    &-cat {
        color: @bl-100;
        transition: @bezier;
        &-wrap {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 20px @space 0 0;
            background: @w-100;
            transition: @bezier;
        }

        &-txt {
            color: @dgy-100;
            transition: @bezier;
        }
    }

    &-date {
        margin-top: @space*0.66;
        margin-bottom: @space*0.66;
        font-family: @font-family-heading;
        color: @dgy-100;
    }

    &-txt {
        color: @font-colour;
    }

    &-btn {
        display: inline-flex;
        align-items: center;
        margin-top: @space*0.66;
        font-size: inherit;
        transition: @bezier;
        &-txt {
            margin-right: @space/2;
            color: @rd-100;
            transition: @bezier;
            &:after {
                background-color: @rd-100;
            }
        }
    }

    @media screen and (min-width: @screen-sm) {
        &-txt {
            min-height: 95px;
        }
    }
}