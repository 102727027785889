.CookieBar {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    pointer-events: none;
    z-index: 9999;
    &.is-active {
        display: block;
    }

    &-banner {
        background-color: @w-100;
        box-shadow: 0 1px 8px rgba(0, 0, 0, .085), 0 8px 24px rgba(0, 0, 0, .1);
        pointer-events: auto;
        padding: @space 0;
    }

    &-inner {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .Button {
        .Icon {
            flex-shrink: 0;
        }
    }
}