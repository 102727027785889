.BlockShow {
	&--featured {
		position: relative;
		display: block;
		width: 100%;
		padding-top: @pad-md-tb;
		padding-bottom: @pad-md-tb;
        padding-left: @pad-lg-lr;
        padding-right: @pad-lg-lr;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2004 1208' fill='%23000' class='background-checked' preserveAspectRatio='xMidYMid slice'%3E%3Cpath d='M 2004 0 L 1002 1208 H 2004 Z' opacity='0.04'%3E%3C/path%3E%3Cpath d=' M 1002 0 L 2004 1208 V 0 Z' opacity='0.04'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
		background-size: cover;
		background-position: top 0 right 45%;
		&.u-content--positive {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2004 1208' fill='%23000000' class='background-checked' preserveAspectRatio='xMidYMid slice'%3E%3Cpath d='M 2004 0 L 1002 1208 H 2004 Z' opacity='0.09'%3E%3C/path%3E%3Cpath d=' M 1002 0 L 2004 1208 V 0 Z' opacity='0.09'%3E%3C/path%3E%3C/svg%3E");
		}

        h2 {
        	color: @bl-100;
    		font-weight: 600;
        }
    }

    &--featured & {
    	&-content {
    		.u-img-wrap {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				> a {
					max-width: 100%;
					flex-basis: 100%;
				}
    		}

    		&-wrap {
    			display: flex;
    			align-items: center;
    			z-index: 4;
    		}
    	}
    }

    &-hr {
        position: relative;
        width: 100%;
        height: 3px;
        margin: @space/3 0 @space*1.5;
        border-top: 1px solid @rd-100;
        background: linear-gradient(90deg,rgba(0, 35, 156, 0), @bl-100 50%,rgba(0, 35, 156, 0));
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: @w-100;
        }
    }

	&-title {
		&-sub {
			h4 {
				margin: 0;
				font-family: @font-family-heading;
				font-size: @font-size;
				font-weight: 600;
				color: @bl-100;
			}
		}
	}

	&-content {
		ul {
			li {
				font-family: @font-family-heading;
				font-size: @font-size-h4;
				&:last-child {
					margin-bottom: @space/2;
				}
			}
		}
	}

    @media screen and (min-width: 320px) {
    	&-title {
			&-sub {
				font-size: calc(~"16px + (18 - 16) * ( (100vw - 320px) / ( 1024 - 320) )");
			}
		} 
    }

    @media only screen and (min-width: @screen-sm) {
    	&--featured {
	        padding-left: @pad-md-lr;
	        padding-right: @pad-md-lr;
	    }

	    &--featured & {
	    	&-content {
	    		.u-img-wrap {
					> a {
						max-width: 50%;
						flex-basis: 50%;
					}
	    		}
	    	}
	    }

	    &-title {
			&-sub {
				position: absolute;
				top: @pad-sm-lr;
				margin-bottom: 0;
				font-size: calc(~"15px + (18 - 15) * ( (100vw - 1024px) / ( 1920 - 1024) )");
			}
		}
    }
	
	@media screen and (min-width: 992px) {
		&--featured {
			display: flex;
			padding-top: 0;
			padding-bottom: 0;
		}

		&--featured & {
	    	&-content {
	    		.u-img-wrap {
					> a {
						max-width: 100%;
						flex-basis: 100%;
					}
	    		}
	    	}
	    }

		&-content {
			margin-bottom: 0;
		}
    }
}

// .TourShow {
// 	.BlockShow {
// 		&--featured {
// 			background-color: @rd-100;
// 			h2 {
// 				color: @w-100;
// 			}
// 		}
// 	}
// }
