.Header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 90px;
    background: @w-100;
    padding: 0 @pad-md-lr;

    &-logo {
        &-img {
            width: calc(~"150px + (160 - 150) * ( (100vw - 320px) / ( 1024 - 320) )");
            height: 60px;
        }
    }

    &-nav {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        padding-right: 0;
    }

    @media screen and (min-width: @screen-md) {
        height: calc(~"80px + (106 - 80) * ( (100vw - 1024px) / ( 1920 - 1024) )");
        padding-right: @pad-sm-lr;
        padding-left: @pad-sm-lr;

        &-nav {
            padding-top: calc(~"10px + (20 - 10) * ( (100vw - 1024px) / ( 1920 - 1024) )");
            padding-bottom: calc(~"10px + (20 - 10) * ( (100vw - 1024px) / ( 1920 - 1024) )");
            padding-right: 0;
        }

        &-logo {
            &-img {
                width: calc(~"160px + (220 - 160) * ( (100vw - 1024px) / ( 1920 - 1024) )");
                height: 80px;
            }
        }

        &-nav {
            flex: 1;
            display: flex;
            justify-content: flex-start;
        }
    }
}