.Section {
	&--tb {
		padding-top: @pad-md-tb;
		padding-bottom: @pad-md-tb;
	}

	&--tb-w {
		padding-top: @pad-md-tb;
		padding-bottom: @pad-md-tb;
		background: @w-100;
	}

	&--tb-bl {
		padding-top: @pad-md-tb;
		padding-bottom: @pad-md-tb;
		background: @bl-100;
	}

	&--tb-dgy {
		padding-top: @pad-md-tb;
		padding-bottom: @pad-md-tb;
		background: @dgy-100;
	}

	&--top {
		padding-top: @pad-md-tb;
	}

	&--btm {
		padding-bottom: @pad-md-tb;
	}

	&--btm-w {
		padding-bottom: @pad-md-tb;
		background: @w-100;
	}

	&--btn {
		.Button {
			min-width: 100%;
			&-wrap {
				&--btm {
					margin-top: @pad-md-tb;
				}
			}

			span {
				margin-left: 0;
			}
		}
	}

	@media screen and (min-width: @screen-xs) {
		&--btn {
			.Button {
				min-width: 50%;
			}
		}
	}

	@media screen and (min-width: 992px) {
		&--btn {
			.Button {
				min-width: 33.33%;
			}
		}
	}
}