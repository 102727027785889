.CategoryList {
    position: sticky;
    top: @space*1.5;
    margin-bottom: @space*1.5;

    &-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style: none;
    }

    &-title {
        font-size: @font-size-h3;
        margin-bottom: @space/2;
    }

    &-item {
        max-width: 100%;
        flex-basis: 100%;
        padding: @space/6 @space*0.66;
        background: @lgy-080;
        transition: @bezier;

        span {
            transition: @bezier;
        }

        &.is-active {
            span {
                color: @rd-100;
            }

            .Icon {
                fill: @rd-100;
            }
        }

        &:hover {
            span {
                color: @rd-100;
                &:after {
                    background-color: @rd-100;
                }
            }
            .Icon {
                fill: @rd-100;
            }
        }
    }

    &-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media screen and (min-width: @screen-xs) {
        &-item {
            max-width: 50%;
            flex-basis: 50%;
        }
    }

    @media screen and (min-width: @screen-sm) {
        &-item {
            max-width: 33.33%;
            flex-basis: 33.33%;
        }
    }

    @media screen and (min-width: 992px) {
        margin-bottom: 0;
        &-item {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
}