/*	********* content.less *********
    ************************************************************************* */

// Import a few bits and pieces for tinyMCE
@import './base.less';

// Add some padding & styles to the tinyMCE editor window
.mce-content-body {
	padding: 15px;
}

html, body {
    font-family: @font-family;
    font-size: @font-size;
    font-weight: 400;
    line-height: @line-height;
    color: @font-colour;
    background-color: @colour-background;

    @media screen and (min-width: 320px) {
        font-size: calc(~"16px + (18 - 16) * ( (100vw - 320px) / ( 1024 - 320) )");
    }

    @media screen and (min-width: @screen-md) {
        font-size: calc(~"15px + (18 - 15) * ( (100vw - 1024px) / ( 1920 - 1024) )");
    }
}
