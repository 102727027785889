.box() {
    .typographyColor(white);
    background-color: @bl-100;
    padding: @space*1.5 @pad-sm-lr;
}

#rounded {

    .small {
        border-radius: @border-radius-default;
    }

    .medium {
        border-radius: @border-radius-default;
    }

    .large {
        border-radius: @border-radius-default;
    }
}

#style {

    .shadow {
        -moz-box-shadow:    0px 2px 4px 0px rgba(0,0,0,0.50);
        box-shadow:         0px 2px 4px 0px rgba(0,0,0,0.50);
    }
}
