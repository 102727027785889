.Tour() {
    .Tour {
        display: flex;
        flex-wrap: wrap;
        &:focus, &:hover {
            span {
                &:after {
                    width: 0%;
                }
            }

            .Tour {
                &-btn {
                    &-txt {
                        &:after {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &-image, &-info {
            &-wrap {
                max-width: 100%;
                flex-basis: 100%;
            }
        }

        &-image {
            display: block;
            &-wrap {
                position: relative;
                overflow: hidden;
            }
        }

        &-length {
            color: @bl-100;
            transition: @bezier;
            &-wrap {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 20px @space 0 0;
                background: @w-100;
                transition: @bezier;
            }

            &-txt {
                color: @dgy-100;
                transition: @bezier;
            }
        }

        &-info {
            display: flex;
            flex-wrap: wrap;
            margin-top: auto;
            margin-bottom: @space;
            color: @dgy-100;
            &-wrap {
                display: flex;
                flex-wrap: wrap;
                padding: @space*0.66 0 0 0;
                transition: @bezier;
                > * {
                    max-width: 100%;
                    flex-basis: 100%;
                }
            }

            span {
                display: block;
                font-family: @font-family-heading;
                color: @bl-100;
            }
        }

        &-title {
            margin-bottom: 0;
            color: @bl-100;
            font-weight: 600;
        }

        &-date, &-price {
            &-wrap {
                flex: 1 1 50%;
                margin-bottom: @space/2;
            }
        }

        &-btn {
            bottom: @pad-sm-lr;
            right: @pad-sm-lr;
            display: flex;
            align-items: center;
            &-txt {
                position: relative;
                margin-right: @space/3;
                font-weight: 600;
                font-family: @font-family-heading;
                color: @rd-100;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    width: 0;
                    height: 1px;
                    background-color: @rd-100;
                    transition: @bezier;
                }
            }
        }

        &-difficulty {
            margin-top: @space/2;
            &-wrap {
                max-width: 50%;
                flex: 0 0 50%;
                img {
                    width: 100%;
                }
            }
        }

        &-ribbon {
            position: absolute;
            top: 30px;
            left: -70px;
            width: 250px;
            padding: @space/3;
            font-family: @font-family-heading;
            text-align: center;
            text-transform: uppercase;
            color: @w-100;
            transform: rotate(-45deg);
            box-shadow: 3px 3px 15px rgba(0,0,0,0.4);

            &--featured {
                background: @gn-100;
                 &.is-hidden {
                    display: none;
                }
            }

            &--lastFew {
                background: @oe-100;
            }
            
            &--soldOut {
                background: @rd-100
            }

            span {
                font-size: 16px;
            }
        }

        @media screen and (min-width: @screen-md) {
            &:focus, &:hover {
                span {
                    &:after {
                        width: 0%;
                    }
                }

                .Tour {
                    &-length {
                        color: @w-100;
                        &-wrap {
                            padding: 20px @space;
                            .typographyColor(@w-100);
                            background: @rd-100;
                        }
                    }

                    &-btn {
                        &-txt {
                            &:after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
