.TourIndex {
    &-tours {
        .Tour();
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        > * {
            padding: 0;
            max-width: 100%;
            flex-basis: 100%;
        }

        > * + * {
            margin-top: @space*1.5;
        }
    }

    @media screen and (min-width: @screen-sm) {
        &-tours {
            margin: -(@space*0.75);
            > * {
                padding: @space*0.75;
                max-width: 50%;
                flex-basis: 50%;
            }

            > * + * {
                margin-top: 0;
            }
        }
    }

    @media screen and (min-width: 992px) {
        &-tours {
            > * {
                max-width: 33.33%;
                flex-basis: 33.33%;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        &-tours {
            margin: -(@space);
            > * {
                padding: @space;
            }
        }
    }
}