.BlockList {
	position: relative;

	&--w {
		background: @w-100;
	}
	
	&--featured {
		display: flex;
		background: @w-100;
	}

	&--grid {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
        > * {
        	margin-top: 45px;
            padding: 0;
            max-width: 100%;
            flex-basis: 100%;
        }
	}

	&--grid & {
		&-block {
			.BlockShow {
				&-title {
					margin-top: 1.25rem;
					margin-bottom: 1rem;
				}

				&-btn {
			        display: inline-flex;
			        align-items: center;
			        min-width: auto;
			        margin-top: 1.5rem;
			        font-size: inherit;
			        transition: @bezier;
			        &-txt {
			            margin-right: @space/2;
			            color: @rd-100;
			            transition: @bezier;
			            &:after {
			                background-color: @rd-100;
			            }
			        }
			    }
			}
		}
	}

	&-block {
		&-btn {
			position: relative;
			min-width: auto;
			padding: @space/3 @space/3;
			background: @w-100;
			&-wrap {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				margin-top: @space*0.66;
			}

			span{
				color: @rd-100;
			}
		}
	}

	.Truspilot {
        &-logo {
        	position: relative;
        }
    }

	&--links {
		&-block {
	        max-width: 100%;
	        flex-basis: 100%;
	    }
	}

	@media screen and (min-width: @screen-xs) {
		&--grid {
            margin: -(@space/2);
            > * {
                padding: @space/2;
                max-width: 50%;
                flex-basis: 50%;
            }
		}

		&--grid & {
			&-block {
				.BlockShow {
					&-content {
						min-height: 95px;
					}
				}
			}
		}
	}

	@media screen and (min-width: @screen-sm) {
		&--grid {
            margin: -(@space*0.75);
            > * {
                padding: @space*0.75;
            }
		}

		&--grid & {
			&-block {
				margin-top: 45px;
			}
		}

		&--links & {
			&-block {
	           max-width: 50%;
	           flex-basis: 50%;
	        }
		}
	}

	@media screen and (min-width: 850px) {
		&-block {
			&-btn {
				&-wrap {
					position: absolute;
					right: 0;
					bottom: 0;
					width: 50%;
					margin-top: 0;
				}
			}
		}
	}

	@media screen and (min-width: 992px) {
        &--grid {
            > * {
                max-width: 33.33%;
                flex-basis: 33.33%;
            }
        }
    }

	@media screen and (min-width: 1200px) {
		&--grid {
			margin: -(@space);
            > * {
                padding: @space;
            }
		}

		&--grid & {
			&-block {
				.BlockShow {
					&-content {
						min-height: 108px;
					}
				}
			}
		}
	}
}