.Anchor {
	display: flex;
	align-items: center;
    transition: @bezier;
    span {
        &:after {
            background-color: @rd-100;
            transition: @bezier;
        }
    }

    &--hero, &--feature {
        bottom: @pad-sm-lr;
    }

    &--hero {
        left: @pad-md-lr;
        color: @w-100;
        span {
            margin-left: @space/2;
            &:after {
                background-color: @w-100;
                transition: @bezier;
            }
        }

        &.is-desktop {
            display: none;
        }

        &.is-mobile {
            display: none;
        }
    }

    &--feature {
        margin-top: 45px;
        right: @pad-sm-lr;
        color: @rd-100;
        span {
            margin-right: @space/2;
        }
    }

    @media screen and (min-width: @screen-sm) {
        &--hero, &--feature {
            position: absolute;
            z-index: 4;
        }

        &--hero {
            &.is-mobile {
                display: flex
            }
        }
    }

    @media screen and (min-width: 992px) {
        &--hero, &--feature {
            bottom: @pad-sm-lr;
        }

        &--hero {
            left: @pad-sm-lr;
            &.is-desktop {
                display: flex
            }

            &.is-mobile {
                display: none
            }
        }

        &--feature {
            span {
                margin-right: @space/2;
            }
        }

        &--phone {
            display: flex;
        }
    }
}