.PageShow--home .ImageCarousel-title {
    display: none;
}

.Title {
    margin-bottom: 0;
	font-size: @font-size-h2;
    font-weight: 900;
    letter-spacing: -1.5px;
    line-height: 1.15;
    color: @w-100;
	&-wrap {
		display: flex;
        flex-wrap: wrap;
		align-items: center;
        padding-top: @space;
        padding-bottom: @space;
        background-color: @bl-100;
	}

	&-txt {
        margin-top: @space/4;
        max-width: 100%;
        flex-basis: 100%;
        font-size: @font-size-h4;
        color: @w-100;
        > * {
            color: @w-100;
        }

        h4 {
            margin-bottom: 0;
        }
	}

    @media screen and (min-width: 675px) {
        &-wrap {
            background-image: url('../svg/title_banner_02.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top 0 right 0;
        }
    }

    @media screen and (min-width: @screen-sm) {
        &-wrap {
            padding-top: @space*1.5;
            padding-bottom: @space*1.5;
        }
    }

    @media screen and (min-width: @screen-md) {
        font-size: @font-size-h1;
    }
}