.Footer {
    &-top, &-btm {
        display: flex;
        flex-wrap: wrap;
    }

    &-top, &-btm {
        &-col {
            &--left, &--right {
                position: relative;
            }
        }
    }

    &-top, &-btm {
        &-col {
            max-width: 100%;
            flex-basis: 100%;
            padding-left: @pad-lg-lr;
            padding-right: @pad-lg-lr;
        }
    }

    &-top {
        &-col {
            padding-top: @pad-md-tb;
            padding-bottom: @pad-md-tb;
            &--left {
                background: @bl-100;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2004 1208' fill='%23000' class='background-checked' preserveAspectRatio='xMidYMid slice'%3E%3Cpath d='M 0 0 L 1002 1208 H 0 Z' opacity='0.09'%3E%3C/path%3E%3Cpath d='M 0 0 H 1002 L 0 1208 Z' opacity='0.09'%3E%3C/path%3E%3C/svg%3E");
                background-position: top 0 left 50%;
                background-repeat: no-repeat;
                background-size: cover;
            }

            &--right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2004 1208' fill='%23000' class='background-checked' preserveAspectRatio='xMidYMid slice'%3E%3Cpath d='M 2004 0 L 1002 1208 H 2004 Z' opacity='0.04'%3E%3C/path%3E%3Cpath d=' M 1002 0 L 2004 1208 V 0 Z' opacity='0.04'%3E%3C/path%3E%3C/svg%3E");
                background-position: top 0 right 50%;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }

    &-btm {
        &-col {
            background: @bl-100;
            &--left, &--right {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            &--left {
                padding-bottom: @space;
                padding-top: @space/2;
                order: 2;
            }

            &--right {
                padding-bottom: @space/2;
                padding-top: @space*1.5;
                order: 1;
            }
        }
    }

    &-logo {
        &-img {
            width: calc(~"150px + (160 - 150) * ( (100vw - 320px) / ( 1024 - 320) )");
            height: 60px;
        }
    }

    &-form, &-content {
        position: relative;
        z-index: 2;
    }

    &-nav, &-contact {
        margin-top: @space*1.5;
    }

    &-nav {
        max-width: 100%;
        flex-basis: 100%;
        &-wrap {
            display: flex;
            flex-wrap: wrap;
        }

        .Menu {
            &-root {
                display: block;
            }
        }
    }

    &-contact {
        display: flex;
        flex-wrap: wrap;
        &-col {
            max-width: 100%;
            flex-basis: 100%;
            margin-bottom: @space*1.5;
            > * + * {
                margin-top: @space/3;
            }
        }
    }

    &-company, &-wfuk {
        font-size: 14px;
    }

    &-wfuk {
        color: @bl-100;
        a {
            border-bottom: solid 1px @w-100;
        }

        span {
            font-family: @font-family;
            font-weight: 400;
        }
    }

    &-links {
        flex: 1;
    }

    @media screen and (min-width: @screen-sm) {
        &-top, &-btm {
            &-col {
                max-width: 50%;
                flex-basis: 50%;
                padding-left: @pad-md-lr;
                padding-right: @pad-md-lr;
            }
        }

        &-btm {
            &-col {
                padding-top: @space;
                padding-bottom: @space;
                justify-content: flex-start;
                    text-align: left;
                &--left {
                    order: 1;
                }

                &--right {
                    order: 2;
                }
            }
        }

        &-company, &-wfuk {
            font-size: 16px;
        }

        &-links {
            .Menu {
                margin-bottom: @space/2;
            }
        }
    }

    @media screen and (min-width: @screen-md) {
        &-logo {
            &-img {
                width: calc(~"160px + (220 - 160) * ( (100vw - 1024px) / ( 1920 - 1024) )");
                height: calc(~"60px + (80 - 60) * ( (100vw - 1024px) / ( 1920 - 1024) )");
            }
        }

        &-nav {
            max-width: 50%;
            flex-basis: 50%;
        }
    }

    @media screen and (min-width: 1200px) {
        &-top, &-btm {
            &-col {
                padding-left: calc(~"30px + (240 - 30) * ( (100vw - 1200px) / ( 1920 - 1200) )");
                padding-right: calc(~"30px + (240 - 30) * ( (100vw - 1200px) / ( 1920 - 1200) )");
            }
        }
    }

    @media screen and (min-width: 1920px) {
        &-top, &-btm {
            &-col {
                padding-left: 240px;
                padding-right: 240px;
            }
        }

        &-logo {
            &-img {
                width: 220px;
                height: 80px;
            }
        }
    }
}