.PageShow--home, .PageShow--default {
    .PageShow {
        &-content {
            &-wrap {
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                > * {
                    padding: 0;
                    max-width: 100%;
                    flex-basis: 100%;
                }

                > * + * {
                    margin-top: @space*1.5;
                }
            }

            &--img {
                > * + * {
                    margin-top: @space*1.5;
                }
            }
        }

        @media screen and (min-width: @screen-sm) {
            &-content {
                &-wrap {
                     margin: -(@space*0.75);
                    > * {
                        padding: @space*0.75;
                    }

                    > * + * {
                        margin-top: 0;
                    }
                }

                &--content, &--img {
                    max-width: 50%;
                    flex-basis: 50%;
                }

                &--img {
                    display: block;
                    > * + * {
                        margin-top: @space*1.5;
                    }
                }
            }
        }

        @media screen and (min-width: 992px) {
            &-content {
                &--img {
                    max-width: 33.33%;
                    flex-basis: 33.33%;
                }

                &--content {
                    max-width: 66.66%;
                    flex-basis: 66.66%;
                }
            }
        }

        @media screen and (min-width: 1200px) {
            &-content {
                &-wrap {
                    margin: -(@space);
                    > * {
                        padding: @space;
                    }
                }
            }
        }
    }
}