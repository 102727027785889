/* ------------------------------------------------------------
	General Styles
------------------------------------------------------------ */

.sitemap {
	position: relative;
    padding: 0;
    margin: 0 auto;
    z-index: 2;
}

.sitemap ul li {
    margin: 0;
}

/* ------------------------------------------------------------
	Page Styles
------------------------------------------------------------ */

/* -------- Top Level --------- */

.primaryNav {
	clear: both;
	width: 100%;
}
.primaryNav #home {
	position: absolute;
	margin-top: -3em;
	margin-bottom: 0;
	min-width: 11.5em;
	width: 100%;
}
.primaryNav #home:before {
	display: none;
}
.primaryNav ul {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	list-style: none;
}
.primaryNav li {
	flex: 1;
	flex-basis: 11.5em;
	padding-right: 1.25em;
	position: relative;
}
.primaryNav > ul > li {
	margin-top: 3em;
}
.primaryNav li a {
	margin: 0;
	padding: .875em .9375em .9375em .9375em;
	display: block;
	font-family: @font-family-heading;
	font-size: .9375em;
	color: @w-100;
	background: @rd-100;
	text-decoration: none;
}
.primaryNav li a:hover {
	background-color: darken(@bl-100, 10%);
}
.primaryNav a:link:after,
.primaryNav a:visited:after,
.utilityNav a:link:after,
.utilityNav a:visited:after {
	// content: " "attr(href)" ";
	display: block;
	font-weight: 600;
	font-size: .75em;
	margin-top: .25em;
	word-wrap: break-word;
	color: rgba(255,255,255,.75);
}

/* -------- Second Level --------- */

.primaryNav ul ul {
	display: block;
}
.primaryNav ul ul li {
	padding-top: .6875em;
	padding-right: 0;
}
.primaryNav ul ul li a {
	font-family: @font-family;
	background: @bl-100;
}
.primaryNav ul ul li a:hover {
	background-color: @bl-100;
}
.primaryNav ul ul li:first-child {
	padding-top: 2em;
}

/* -------- Third Level --------- */

.primaryNav ul ul ul {
	margin-top: .6em;
	padding-top: .6em;
	padding-bottom: .625em;
}
.primaryNav ul ul ul li {
	padding-top: .3125em;
	padding-bottom: .3125em;
}
.primaryNav ul ul ul li a {
	background-color: #ff9d00;
	font-size: .75em;
	padding: .75em;
	width: 90%;
	margin-right: 0;
	margin-left: auto;
}
.primaryNav ul ul ul li a:hover {
	background-color: #ff831b;
}
.primaryNav ul ul ul li:first-child {
	padding-top: 1em;
}
.primaryNav ul ul ul li a:link:after,
.primaryNav ul ul ul li a:visited:after {
	font-size: .75em;
}

/* --------	Fourth Level --------- */

.primaryNav ul ul ul ul {
	margin-top: 0;
	padding-top: .3125em;
	padding-bottom: .3125em;
}
.primaryNav ul ul ul ul li a {
	background-color: #de003a;
	padding: .75em ;
	width: 80%;
}
.primaryNav ul ul ul ul li a:hover {
	background-color: #c20035;
}
.primaryNav ul ul ul ul li a:link:after,
.primaryNav ul ul ul ul li a:visited:after {
	display: none;
}

/* ------------------------------------------------------------
	Connecting Lines:
	Uncomment 'border-color: red' for debugging
------------------------------------------------------------ */

.primaryNav ul:before,
.primaryNav ul:after,
.primaryNav ul li:before,
.primaryNav ul li:after {
	display: block;
	content: '';
	position: absolute;
	border-width: 0;
	border-color: @dgy-100;
	border-style: solid;
	z-index: -1;
	/* border-color: red; */
}
.primaryNav > ul > li:before {
	height: 1.375em;
	top: -1.375em;;
	right: calc(50% + .625em);
	width: calc(100% - 2px);
	border-top-width: 1px;
	border-right-width: 1px;
}
.primaryNav > ul > li:first-child + li:before {
	border-top-width: 0;
	height: 5em;
	top: -5em;
}
.primaryNav ul ul li:after {
	width: 50%;
	height: .6875em;
	top: 0;
	right: 1px;
	border-left-width: 1px;
}
.primaryNav ul ul li:first-child:before {
	width: 50%;
	height: 1.3125em;
	top: .6875em;
	right: 1px;
	border-left-width: 1px;
	/* border-color: red; */
}
.primaryNav > ul > li:last-child:after {
	border-bottom-width: 0;
}
.primaryNav ul ul ul li:before {
	width: calc(50% - 5px) !important;
	height: calc(100% - 2px);
	top: -50%;
	left: 0;
	border-left-width: 1px;
	border-bottom-width: 1px;
	/* border-color: red; */
}
.primaryNav ul ul ul li:first-child:before {
	height: 2.125em;
	top: -1px;
	border-top-width: 2px;
	/* border-color: red; */
}
.primaryNav ul ul ul:before {
	width: 50%;
	height: 1.25em;
	top: -10px;
	right: 1px;
	border-left-width: 2px;
	/* border-color: red; */
}
.primaryNav ul ul ul li:after {
	border-width: 0;
}
.primaryNav ul ul ul ul li:before,
.primaryNav ul ul ul ul li:first-child:before {
	display: none;
}

.primaryNav ul ul ul ul:before {
	width: 1px;
	height: calc(100% + 2.5em);
	top: -2.5em;
	left: 0;
	border-left-width: 2px;
	/* border-color: red; */
}

/* ------------------------------------------------------------
	Utility Navigation
------------------------------------------------------------ */

.utilityNav {
	float: right;
	margin-top: 0;
	margin-bottom: -.25em;
	margin-right: 1.25em;
	max-width: 48%;
	list-style-type: none;
}
.utilityNav li {
	padding: 0 0 .625em .625em;
	display: inline-block;
}
.utilityNav li:first-child {
}
.utilityNav li a {
	display: block;
	font-size: .75em;
	font-weight: 700;
	padding: .75em 1em;
	font-weight: bold;
	text-align: left;
	color: white;
	background-color: #ff9d00;
	border: 1px solid  rgba(0,0,0,.025);
	box-shadow: 0px 2px 0 rgba(0,0,0,0.15);
	text-shadow: 0 0 10px rgba(0,0,0,.15);
	text-decoration: none;
}
.utilityNav li a:hover {
	background-color: #ff831b;
}
.utilityNav li a:link:after,
.utilityNav li a:visited:after {
	color: rgba(255,255,255,.75);
	font-size: .75em;
	font-weight: 600;
	margin-top: .25em;
}

/* ------------------------------------------------------------
	Responsive Styles
------------------------------------------------------------ */

@media screen and (max-width: 30em) {
	.primaryNav ul {
		display: block;
	}
	.primaryNav li {
		width: 100%;
		padding-right: 0;
	}
	.primaryNav #home {
		width: 100%;
		position: relative;
		margin-bottom: -1em;
		margin-top: 0;
	}
	.utilityNav {
		float: none;
		display: block;
		width: 100%;
		text-align: right;
		margin-bottom: 2.5em;
		max-width: 100%;
	}
}

/* The media queries below keep the grid column widths intact when
a column wraps. This prevents the wrapped column from displaying at
100% width. These queries also make the homepage line item flex
appropriately. When your sitemap is complete, remove the queries
that you don't need, and your sitemap layout will work perfectly. */

/* two column */
@media screen and (min-width: 480px) {
	.primaryNav {
		clear: both;
		width: 100%;
		margin-top: 4.5rem;
	}

	.primaryNav > ul > li {	
		max-width: 100%; 
	}
}
/* three column */
@media screen and (min-width: 600px) {
	.primaryNav > ul > li {	max-width: 100%; }
}
/* four column */
@media screen and (min-width: 800px) {
	.primaryNav > ul > li {	max-width: 50%; }
}
/* five column */
@media screen and (min-width: 970px) {
	.primaryNav > ul > li {	max-width: 50%; }
}
/* six column */
@media screen and (min-width: 1170px) {
	.primaryNav > ul > li {	max-width: 25%; }
}
/* seven column */
@media screen and (min-width: 1366px) {
	.primaryNav > ul > li {	max-width: 25%;	}
}
/* eight column */
@media screen and (min-width: 1540px) {
	.primaryNav > ul > li {	max-width: 25%; }
}
/* nine column */
@media screen and (min-width: 1700px) {
	.primaryNav > ul > li {	max-width: 20%; }
}
/* ten column */
@media screen and (min-width: 1920px) {
	.primaryNav > ul > li {	max-width: 20%; }
}
