.TagList {
    float: left;
    margin-bottom: @space * 2;

    h2 {
        line-height: 100%;
        margin-bottom: @space / 2;
    }

    &-list {
        list-style: none;

        li {
            margin: 0 15px 0 0;
            float: left;

            a {
                font-weight: 400;

                span.fa {
                    margin-right: 3px;
                }
            }
        }
    }

    &-item {


    }

    &-link {


    }

}
