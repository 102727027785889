.no-js {
	#Loading {
		display: none;
	}
}

.js {
	#Loading {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: @w-100;
		z-index: 100000;
		&-logo {
			width: 60px;
			margin-bottom: @space/3;
			overflow: hidden;
		}

		&-txt {
			font-family: @font-family-heading;
			font-weight: 500;
			font-size: 16px;
    		letter-spacing: 5px;
			animation: pulse 0.7s linear infinite alternate forwards;
		}
	}
}

@keyframes pulse {
  from {
  	opacity: 0.3;
  }

  50% {
  	opacity: 1;
  }

  to {
  	opacity: 0.3;
  }
}