// Lead text
p {
    &.u-lead {
        font-size: @font-size-h4;
    }

    &.u-cite {
        margin-bottom: 0;
        font-family: @font-family;
        font-size: @font-size;
        font-weight: 400;
        line-height: @line-height;
        color: @font-colour;

        @media screen and (min-width: 320px) {
            font-size: calc(~"16px + (18 - 16) * ( (100vw - 320px) / ( 1024 - 320) )");
        }

        @media screen and (min-width: @screen-md) {
            font-size: calc(~"15px + (18 - 15) * ( (100vw - 1024px) / ( 1920 - 1024) )");
        }
    }

    &.u-error, &.u-success {
        position: relative;
        margin-left: 0.5rem;
        padding-left: 2.5rem;
        padding-bottom: 5px;
        background-repeat: no-repeat;
        background-position: top 2px left 0;
    }

    &.u-error {
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='25px' height='25px' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E .a %7B fill: %23da291c; %7D .b %7B fill: none; stroke: %23fff; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Ctitle%3Eerror%3C/title%3E%3Cg%3E%3Ccircle class='a' cx='25' cy='25' r='25'/%3E%3Cpolyline class='b' points='16 34 25 25 34 16'/%3E%3Cpolyline class='b' points='16 16 25 25 34 34'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    &.u-success {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25px' height='25px' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E .a %7B fill: %23108927; %7D .b %7B fill: none; stroke: %23fff; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Ctitle%3Esuccess%3C/title%3E%3Cg%3E%3Ccircle class='a' cx='25' cy='25' r='25'/%3E%3Cpolyline class='b' points='38 15 22 33 12 25'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    &.u-noTop {
        margin-top: 0;
    }
}

h1.u-lead,
h2.u-lead,
h3.u-lead,
h4.u-lead,
h5.u-lead,
h6.u-lead,
.u-leadHeading {
    //border-bottom: 1px solid @bl-100;
}

// Alignment
.u-textLeft           { text-align: left; }
.u-textRight          { text-align: right; }
.u-textCenter         { text-align: center; }
.u-textJustify        { text-align: justify; }
.u-textNoWrap         { white-space: nowrap; }

// Change alignment for smaller screens
@media screen and (max-width: @screen-sm){
    .u-textCenter--sm {
        text-align: center;
    }

    .u-textleft--sm {
        text-align: left;
    }

    .u-textRight--sm {
        text-align: right;
    }
}

@media screen and (max-width: @screen-sm){
    .u-textCenter--md {
        text-align: center;
    }

    .u-textleft--md {
        text-align: left;
    }

    .u-textRight--md {
        text-align: right;
    }
}


/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, IE 10+
 * 2. Safari 7 and future browsers
 * 3. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 */
.u-textKern {
  font-feature-settings: "kern" 1; /* 1 */
  font-kerning: normal; /* 2 */
  text-rendering: optimizeLegibility; /* 3 */
}


/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */
.u-textTruncate {
  max-width: 100%; /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important; /* 2 */
}

// Transforms
.u-textLowerCase      { text-transform: lowercase; }
.u-textUpperCase      { text-transform: uppercase; }
.u-textCapitalize     { text-transform: capitalize; }

.u-text-lineHeight100 {
    line-height: 100%;
}
