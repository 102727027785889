.TourDateList {
	&-list {
		.Tour();
        display:  flex;
        flex-wrap: wrap;
        margin: 0;
        > * {
            padding: 0;
            max-width: 100%;
            flex-basis: 100%;
        }

        > * + * {
            margin-top: @space*1.5;
        }
	}

	&-excerpt {
		display: flex;
	}

    &-title {
        margin-bottom: @space*1.5;
    }

	@media screen and (min-width: @screen-xs-max) {
        &-list {
            margin: -(@space*0.66);
            > * {
                padding: @space*0.66;
                max-width: 50%;
                flex-basis: 50%;
            }

            > * + * {
                margin-top: 0;
            }
        }
    }

    @media screen and (min-width: 992px) {
        &-list {
            margin: -(@space);
            > * {
                padding: @space;
                max-width: 33.33%;
                flex-basis: 33.33%;
            }
        }
    }
}