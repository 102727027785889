.Testimonial {
	&.slick-slide {
		display: flex;
        flex-wrap: wrap;
	}

    &-img {
        &-wrap {
        	display: none;
        }
    }

    &-quote {
    	&-wrap {
            max-width: 100%;
            flex-basis: 100%;
            margin-top: 0;
            margin-bottom: 0;
            font-size: @font-size-h5;
        }
    }

    @media screen and (min-width: @screen-sm) {
        &-img {
            width: 100%;
            object-fit: cover;
            &-wrap {
                display: block;
                max-width: 50%;
                flex-basis: 50%;
                padding-right: @pad-md-lr;
            }
        }

        &-quote {
            &-wrap {
                max-width: 50%;
                flex-basis: 50%;
            }
        }
    }

    @media screen and (min-width: @screen-md) {
		&-quote {
            font-size: @font-size-h4;
		}
    }
}