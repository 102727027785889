.SocialMedia {
	display: flex;
	flex-wrap: wrap;

	&-link {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-icon {
		width: @space*1.5;
		height: @space*1.5;
	}

	@media screen and (min-width: @screen-md) {
        &-icon {
			width: calc(~"45px + (60 - 45) * ( (100vw - 1001px) / ( 1920 - 1024) )");
			height: calc(~"45px + (60 - 45) * ( (100vw - 1001px) / ( 1920 - 1024) )");
		}
    }
}