// Base Component
// Declare the base component styles along with it's elements here
.DownloadList {

    padding: @space;
    border: @border-default;
    border-radius: @border-radius-base;

    &-list {

        padding: 0;
        margin:0;
        list-style: none;

        &-item {

            padding: 0;
            margin:0;
            margin-bottom: @space;

            &:last-child {
                margin-bottom: 0;
            }

            &-link {

            }
        }
    }

    // Component Modifiers
    // Variations of the base component are declared here.
    // Note that we have an additional '&' after the modifier selector. This targets the parent selector.
    &--buttons & {

        &-link {
            margin: 0;
            margin-bottom: @space;

            &:last-child {
                margin-bottom: 0;
            }
        }

    }

    &--icons & {

        &-link {
            margin: 0;
            margin-bottom: @space;

            &:last-child {
                margin-bottom: 0;
            }
        }

    }

}
