// TourDateBooking
.TourDateBooking {
    &-row {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -(@space/2);
        margin-right: -(@space/2);
    }

    &-col, &-col-100 {
        padding: @space/2;
        max-width: 100%;
        flex-basis: 100%;
    }

    &-col {
        display: flex;
        flex-wrap: wrap;
        > * {
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    h3 {
        span {
            font-size: 16px;
        }
    }

    &-wrap {
        border: 1px solid #c6c6c6;
        padding: @space @pad-xs-lr;
        background: @lgy-080;
        overflow: hidden;
        &--bl {
            border: 2px solid @bl-100;
            color: @w-100;
            background: @bl-100;
            .Button {
                width: 100%;
                color: @w-100;
                border: solid 2px @w-100;
            }
        }
    }

    &-rider {
        color: @bl-100;
        margin-left: 10px;
    }

    .alert {
        &.alert-danger {
            color: @brand-danger;

            ul {
                margin-top: 5px;

                li {
                    margin-left: 45px;
                }
            }
        }
    }

    .has-error {
        .Form-label {
            color: @rd-100;
        }
    }

    &-title {
        margin-top: @space*1.5;
    }

    &-txt {
        font-family: @font-family-heading;
        margin-bottom: @space;
    }

    h4 a {
        cursor: pointer;
    }

    @media screen and (min-width: @screen-md) {
        &-col {
            max-width: 50%;
            flex-basis: 50%;
        }
    }
}