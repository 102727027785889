.PageShow--default {
    .Accordion {
        margin: 0;
        padding: 0;
        border-bottom: 3px solid @lgy-080;
        &-trigger {
            &-wrap {
                border-top: 3px solid @lgy-080;
                color: @bl-100;
                background: @w-100;
                &.is-active {
                    background: @w-100;
                }
            }
        }

        &-panel {
            &.is-open {
                background: @w-100;
            }
        }
    }
}

.TourShow {
    .Accordion {
        margin-top: @space*1.5;
        border-bottom: 1px solid #c6c6c6;
        &-trigger {
            &-wrap {
                border-top: 1px solid #c6c6c6;
                background: none;
                &.is-active {
                    background: @w-100;
                }
            }
        }

        &-icon {
            width: @space;
            height: @space*1.15;
        }

        &-panel {
            &.is-open {
                background: @w-100;
            }
        }
    }
}

.TourDateBooking {
    .Accordion {
        > * + * {
            margin-top: @space/2;
        }

        &-panel {
            &.is-open {
                padding-bottom: @space;
            }
        }

        &-passenger {
            border: 1px solid #c6c6c6;
            background: @lgy-080;
        }

        &-trigger {
            &-wrap {
                color: @bl-100;
            }
        }
    }
}

.PageShow--default, .TourShow, .TourDateBooking {
    .Accordion {
        h3, h4 {
            margin: 0;
            padding: 0;
        }

        &.focus {
            border-color: #c6c6c6;
            background-image: none;
        }

        &-trigger {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: 100%;
            outline: none;
            margin: 0;
            padding: @space/2 @pad-xs-lr;
            &-wrap {
                line-height: 1.15;
                transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);
            }

            &:focus, &:hover {
                background-image: none;
            }

            &:focus, &:hover {
                .Accordion-title {
                    border-color: trnasparent;
                }
            }

            &:hover {
                .Accordion-icon {
                    fill: @rd-100;
                }
            }

            &[aria-expanded="true"] {
                .Accordion-icon {
                    fill: @rd-100;
                    transform: rotate(0deg);
                }
            }
        }

        &-title {
            flex: 0 1 90%;
            display: flex;
            align-items: center;
            outline: none;
            border: 2px solid transparent;
            padding-right: @space/2;
            text-align: left;
            pointer-events: none;
        }

        &-icon {
            flex: 0 1 10%;
            pointer-events: none;
            transform: rotate(45deg);
            transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);
        }

        &-panel {
            max-height: 0;
            margin: 0;
            padding: 0 @pad-xs-lr;
            overflow: hidden;
            transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);
            &.is-open {
                .Accordion-content {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                }
            }

            &[hidden] {
                display: none;
            }

            blockquote {
                margin-bottom: 0;
            }
        }

        &-content {
            position: relative;
            padding-top: 20px;
            padding-left: @pad-sm-lr;
            margin: 0 0 @space*1.5 0;
            border-left: 2px solid @bl-100;
            font-size: @font-size*1.15;
            font-weight: 500;
            line-height: @line-height-heading;
            color: @dgy-100;
            transform: translate3d(0, 100%, 0);
            opacity: 0;
            transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);
            &:before {
                background: none;
                border-left: 1px solid @rd-100;
                content: "";
                display: block;
                position: absolute;
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                pointer-events: none;
            }
        }
    }
}