.Button {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: @space/3 @space*0.66;
	font-size: inherit;
 	transition: @bezier;
 	&-wrap {
 		display: flex;
 		justify-content: center;
 	}

	span {
        position: relative;
        margin-left: @space/2;
        margin-right: @space/2;
        font-family: @font-family-heading;
        font-weight: 600;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 0;
            height: 1px;
            transition: @bezier;
        }
    }

	&--white {
		border: 1.5px solid @w-100;
		background: rgba(255, 255, 255, 1);
		span {
			color: @rd-100;
			&:after {
	            background-color: @rd-100;
	        }
		}
	}

	&--red {
		border: 1.5px solid @rd-100;
		background: @rd-100;
		span {
			color: @w-100;
			&:after {
	            background-color: @w-100;
	        }
		}
	}

	&--redLine {
		border: 1.5px solid @rd-100;
		span {
			color: @rd-100;
			&:after {
				background-color: @rd-100;
			}
		}
	}

	&--blue {
		border: 1.5px solid @bl-100;
		background: @bl-100;
		span {
			color: @w-100;
			&:after {
				background-color: @w-100;
			}
		}
	}

	&--blueNoB {
		span {
			color: @bl-100;
			&:after {
				background-color: @bl-100;
			}
		}
	}

	&--blueLine {
		border: 1.5px solid @bl-100;
		transition: @bezier;
		span {
			color: @bl-100;
		}
	}

	&--darkGrey {
		border: 1.5px solid @dgy-080;
		transition: @bezier;
		span {
			color: @dgy-080;
		}
	}

	&--darkGreyNoB {
		span {
			color: @dgy-080;
			&:after {
				background-color: @dgy-080;
			}
		}
	}
}

.no-touch {
    .Button {
        &:focus,
        &:hover {
            .Icon--red80 {
				fill: @w-100;
			}
        }

        &--white {
			&:hover, &:focus {
				span {
					&:after {
						width: 100%;
					    background-color: @rd-100;
					}
				}
			}
		}

		&--red {
			&:hover, &:focus {
				border: 1.5px solid @rd-100;
				background: darken(@rd-100, 5%);
				span {
					&:after {
						width: 100%;
					    background-color: @w-100;
					}
				}
			}
		}

		&--blue {
			&:hover, &:focus {
				border: 1.5px solid @bl-100;
				background: darken(@bl-100, 5%);
				span {
					&:after {
						width: 100%;
					    background-color: @w-100;
					}
				}
			}
		}

		&--blueNoB {
			&:hover, &:focus {
				span {
					&:after {
						width: 100%;
						color: @bl-100;
					}
				}
			}
		}
    }
}
