*, *:before, *:after {
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-font-smoothing: antialiased
}

a,
body,
button,
div,
footer,
form,
h1,
h2,
h3,
header,
html,
img,
input,
label,
li,
nav,
p,
select,
span,
table,
fieldset,
textarea,
ul {
    margin: 0;
    padding: 0;
    border: 0;
}

button,
input,
select,
textarea {
    display: block;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    outline: none;
    border: none;
    background: none;
    color: inherit
}

button:focus,
input:focus,
textarea:focus {
    outline: solid 1px @bl-100;
}

button:hover,
input[type=submit]:hover {
    cursor: pointer
}

input::-ms-clear {
    display: none
}

textarea {
    overflow: auto
}

a {
    color: inherit;
    text-decoration: none
}

b, strong {
    font-weight: 700;
}

html {
    font-family: @font-family;
    font-size: @font-size;
    font-weight: 400;
    line-height: @line-height;
    color: @font-colour;
    background-color: @colour-background;

    @media screen and (min-width: 320px) {
        font-size: calc(~"16px + (18 - 16) * ( (100vw - 320px) / ( 1024 - 320) )");
    }

    @media screen and (min-width: @screen-md) {
        font-size: calc(~"16px + (20 - 16) * ( (100vw - 1024px) / ( 1920 - 1024) )");
    }
}

html.noscroll {
    overflow: hidden;
}

body.noscroll {
    position: relative;
    width: 100vw;
    height: 85vh;
    overflow: hidden;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}