@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.MenuMain {
		&-root {
			width: 55%;
			justify-content: space-between;
		}
	}

	.TourIndex-tours,
	.TourDateList-list,
	.TourShow .TourInfo-gallery,
	.TourShow .TourInfo-related {
        display: flex;
        flex-wrap: wrap;
        margin-left: -(@space);
        margin-right: -(@space);
    }

	.TourIndex-excerpt,
	.TourDateList-excerpt,
	.TourInfo-gallery-item,
	.TourShow .TourInfo-related .Tour {
        display: flex;
        max-width: 100%;
        flex-basis: 100%;
        padding-left: @space;
        padding-right: @space;
        margin-bottom: @space*1.5;
    }

    .TourInfo-gallery-link {
		width: 100%;
    }

	.Tour {
		&-price {
			&-wrap {
				bottom: 21px;
			}
		}
	}
}

@media screen and (-ms-high-contrast: active) and (min-width: 768px), screen and (-ms-high-contrast: none) and (min-width: 768px) {
    .TourIndex-excerpt,
    .TourDateList-excerpt,
    .TourInfo-gallery-item,
    .TourShow .TourInfo-related .Tour {
        max-width: 50%;
        flex-basis: 50%;
    }
}

@media screen and (-ms-high-contrast: active) and (min-width: 1200px), screen and (-ms-high-contrast: none) and (min-width: 1200px) {
    .TourIndex-excerpt,
    .TourDateList-excerpt {
        max-width: 33.33%;
        flex-basis: 33.33%;
    }
}