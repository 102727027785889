.SearchForm {
    padding: 3px;
    border: solid 1px #E4E4E4;
    border-radius: 6px;
    background-color: #fff;

    &-input {
        border: 0;
        box-shadow: none;
    }

    &-button {
        margin: 2px 0 0 0;
        background: none;
        box-shadow: none;
        border: 0;
        color: #666666;
        padding: 0 8px 0 10px;
        border-left: solid 1px #ccc;

        &:hover {
            border: 0;
            box-shadow: none;
            border-left: solid 1px #ccc;
        }

    }

    .fa {
        font-size: 20px;
    }

}
