.BlogArticleShow {
    &-main {
        padding-top: @pad-md-tb;
        padding-bottom: @pad-md-tb;
        background: @w-100;
    }

    &-grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        > * + * {
            margin-top: @space*1.5;
        }
    }

    &-aside, &-content-wrap, &-back {
        padding: 0;
        max-width: 100%;
        flex-basis: 100%;
    }

    &-pub {
        &-date {
            display: block;
            padding-top: 20px;
            padding-bottom: 20px;
            border-top: 1px solid @lgy-100;
            border-bottom: 1px solid @lgy-100;
        }
    }

    &-content {
        padding-top: @space*1.5;
        padding-bottom: @space*1.5;
        &.u-content {
            p {
                img {
                    display: block;
                    width: 90%;
                    margin: auto;
                }
            }
        }
    }

    &-images {
        &-wrap {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            > * {
                padding: 0;
                max-width: 100%;
                flex-basis: 100%;
            }

            > * + * {
                margin-top: @space*1.5;
            }
        }

        &-link {
            display: block;
        }
    }

    &-hr {
        position: relative;
        height: 3px;
        border-top: 1px solid @rd-100;
        background: linear-gradient(90deg,rgba(0, 35, 156, 0), @bl-100 50%,rgba(0, 35, 156, 0));
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: @w-100;
        }
    }

    &-breadcrumbs {
        background: @colour-neutral;
    }

    &-back {
        margin-top: @space*2;
        text-align: center;
    }

    @media screen and (min-width: @screen-sm) {
        &-images {
            &-wrap {
                margin: -(@space/2);
                > * {
                    padding: @space/2;
                    max-width: 50%;
                    flex-basis: 50%;
                }

                > * + * {
                    margin-top: 0;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        &-grid, &-images-wrap {
            margin: -(@space*0.75);
        }

        &-aside, &-content-wrap, &-images-link {
            padding: @space*0.75;
        }

        &-content {
            &-wrap {
                max-width: 66.66%;
                flex-basis: 66.66%;
            }
        }

        &-aside {
            max-width: 33.33%;
            flex-basis: 33.33%;
        }
    }

    @media screen and (min-width: 1200px) {
        &-grid, &-images-wrap {
            margin: -(@space);
        }

        &-aside, &-content-wrap, &-images-link {
            padding: @space;
        }
    }
}
