.MenuSide {

    // Menu Item (li)
    &-item {

        // Menu Link (a)
        &_link {

            &:hover {

            }
        }

        // Active item highlighting
        &--active a {
            color: #fff;
            background: rgba(0, 0, 0, 0.4);
        }

        // Top level items (horizontal)
        &--top {

        }

    }

    &-sub {
        margin-left: 20px;
    }

}
