//  Tour
@import './tour/index/tourIndex.less';
@import './tour/show/tourShow.less';

// Tour Date
@import './date/list/tourDateList.less';
@import './date/index/tourDateIndex.less';
@import './date/booking/tourDateBooking.less';

// Base template styles
.TourBase {
    &-main {
        padding-top: @pad-md-tb;
        padding-bottom: @pad-md-tb;
        background: @w-100;
    }

    &-container {
		padding-left: @pad-lg-lr;
		padding-right: @pad-lg-lr;
	}

    @media screen and (min-width: @screen-sm) {
    	&-container {
    		padding-left: @pad-md-lr;
    		padding-right: @pad-md-lr;
    	}
    }

    @media screen and (min-width: 1200px) {
        &-container {
            padding-left: calc(~"15px + (240 - 15) * ( (100vw - 1200px) / ( 1920 - 1200) )");
            padding-right: calc(~"15px + (240 - 15) * ( (100vw - 1200px) / ( 1920 - 1200) )");
        }
    }
}
