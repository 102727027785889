.Truspilot {
    &-logo {
        position: absolute;
        z-index: 4;
        &-img {
            width: calc(~"120px + (140 - 120) * ( (100vw - 320px) / ( 992 - 320) )");
        }
    }

    @media screen and (min-width: 992px) {
        &-logo {
            &-img {
                width: calc(~"140px + (180 - 140) * ( (100vw - 992px) / ( 1920 - 992) )");
            }
        }
    }

    @media screen and (min-width: 1920px) {
        &-logo {
            &-img {
                width: 180px;
            }
        }
    }
}

.slick-slider .slick-track {
  display: flex;
  align-items: center;
}

.no-js {
    .ImageCarousel {
        &-slider {
            &-img {
                &-wrap {
                    &:not(:first-child) {
                        display: none;
                    }
                }
            }
        }
    }
}

.ImageCarousel {
    position: relative;
    background-color: @lgy-100;
    &-slider {
        &-img {
            width: 100%;
            object-fit: cover;
            &-wrap {
                background-size: cover;
                background-position: center center;
                height: 80vw;
                will-change: transform;
            }
        }
    }

    &-title {
        margin-bottom: 0;
        font-size: @font-size-h3;
        line-height: 1.15;
        color: @w-100;
        &-wrap {
            padding: @space*0.66 @pad-sm-lr;
            background: rgba(0,38,153, 1);
        }

        &-txt {
            color: @w-100;
            h1, h4 {
                line-height: 1.15;
            }

            h1 {
                margin: 0;
                font-size: @font-size-h3;
            }

            h4 {
                margin: 12px 0 5px 0;
                font-size: 14px;
            }
        }
    }

    .Truspilot {
        &-logo {
            right: @pad-md-lr;
            bottom: @pad-sm-lr;
        }
    }

    @media only screen and (min-width: 400px) {
        &-slider {
            &-img {
                &-wrap {
                    height: 80vw;
                }
            }
        }

        &-title {
            &-txt {
                h4 {
                    font-size: @font-size-h4;
                }
            }
        }
    }

    @media only screen and (min-width: @screen-xs) {
        &-slider {
            &-img {
                &-wrap {
                    height: 60vw;
                }
            }
        }

        &-title {
            font-size: @font-size-h2;
            &-txt {
                h1 {
                    font-size: @font-size-h2;
                }
            }
        }
    }

    @media only screen and (min-width: @screen-sm) {
        &-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.67; 
            z-index: 2;
            background-image: linear-gradient(to right top, #000000, rgba(0,0,0, 0) 56%);
        }

        &-content {
            &-wrap {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                z-index: 4;
            }
        }

        &-title {
            &-wrap {
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                padding: @pad-xs-lr @pad-sm-lr;
                background: rgba(0,38,153,0.85);
                background: linear-gradient(135deg, rgba(0,38,153,0.85) 60%, rgba(0,38,153,0) 90%);
            }
        }
    }

    @media screen and (min-width: 992px) {
        &-slider {
            &-img {
                &-wrap {
                    height: 45vw;
                }
            }
        }

        .Truspilot {
            &-logo {
                right: @pad-sm-lr;
            }
        }
    }

    @media screen and (min-width: @screen-md) {
        &-slider {
            &-img {
                &-wrap {
                    height: 42.5vw;
                }
            }
        }
    }

    @media screen and (min-width: 1920px) {
        &-title {
            &-wrap {
                padding: @space*0.66 @space*1.5;
            }
        }

        .Truspilot {
            &-logo {
                right: @space*1.5;
            }
        }
    }
}