.Form {
    &-group {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }

        .Button {
            margin-top: @space*1.5;
        }
        
        input[type=text], input[type=email], input[type=date], input[type=number], textarea, select {
            display: block;
            width: 100%;
            min-height: 34px;
            outline: 1px solid @dgy-100;
            transition: @bezier;

            &:hover, &:active, &:focus {
                outline: 1px solid @bl-100;
                background-image: none;
            }
        }

        textarea {
            resize: vertical;
        }

        &-inner {
            display: flex;
            flex-wrap: wrap;
            &.-checkbox {
                > * {
                    max-width: 50%;
                    flex-basis: 50%;
                }
            }
        }

        &--btn {
            display: flex;
            justify-content: flex-end;
        }
    }

    &-label, &-question {
        font-family: @font-family-heading;
    }

    &-label {
        &--radio, &--checkbox {
            font-family: @font-family;
        }
    }

    &-question, &-control {
        margin-bottom: @space/3;
    }

    &-control {
        margin-top: @space/3;
        padding: @space/6 @space/3;
        border-radius: 0;
        color: @font-colour;
        background-color: @w-100;
        box-shadow: none;

    }

    &-input {
        &--radio, &--checkbox {
            position: absolute;
            + label {
                display: flex;
                position: relative;
                padding: 0 1.5rem 0 0;
                line-height: 1.15;
                &::before {
                    flex-shrink: 0;
                    content: '';
                    position: relative;
                    display: inline-block;
                    margin-right: @space/2;
                    border: 1px solid #c6c6c6;
                    width: 25px;
                    height: 25px;
                    background: @w-100;
                    transition: @bezier;
                }
            }

            &:checked {
                + label {
                    &::before {
                        background: @w-100;
                    }
                }
            }

            &:focus {
                + label {
                    &::before {
                        outline: solid 1px @bl-100;
                    }
                }
            }
        }

        &--radio {
            + label {
                &::before {
                    border-radius: 50%;
                }
            }

            &:checked {
                + label {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 5px;
                        left: 5px; 
                        height: 15px;
                        width: 15px;
                        border-radius: 50%;
                        background: @rd-100;
                    }
                }
            }
        }

        &--checkbox {
            &:checked {
                + label {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 8px;
                        left: 5px;
                        border-left: 2px solid @rd-100;
                        border-bottom: 2px solid @rd-100;
                        height: 6px;
                        width: 14px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    &-wrap {
        flex: 0 1 100%;
        display: flex;
        align-items: center;
        padding: @space/4 0;
    }

    &-txt {
        margin: @space/2 0;
        a {
            span {
                font-weight: 400;
                font-family: @font-family;
            }
        }
    }

    &-errors {
        margin: @space*1.5 0;
        padding: @space*1.5 @pad-sm-lr;
        background-color: #DB382E;
        > * {
            color: @w-100;
        }

        > ul {
            padding-inline-start: 40px;
        }
    }

    @media screen and (max-width: @screen-xs) {
        &-group {
            .Button {
                width: 100%;
            }

            &-inner {
                &.-checkbox {
                    > * {
                        max-width: 100%;
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
}