.u-container {
    padding-left: @pad-lg-lr;
    padding-right: @pad-lg-lr;

    @media only screen and (min-width: @screen-sm) {
        padding-left: @pad-md-lr;
        padding-right: @pad-md-lr;
    }

    @media only screen and (min-width: 1200px) {
        padding-left: calc(~"30px + (240 - 30) * ( (100vw - 1200px) / ( 1920 - 1200) )");
        padding-right: calc(~"30px + (240 - 30) * ( (100vw - 1200px) / ( 1920 - 1200) )");
    }
}

.u-full {
    max-width: 100%;
    flex-basis: 100%;
}

.u-double {
    max-width: 100%;
    flex-basis: 100%;

    @media screen and (min-width: @screen-sm) {
        max-width: 50%;
        flex-basis: 50%;
    }
}

.u-triple {
    max-width: 33.333%;
    flex-basis: 33.333%;

    @media screen and (max-width: @screen-lg) {
        max-width: 50%;
        flex-basis: 50%;
    }

    @media screen and (max-width: @screen-sm) {
        max-width: 100%;
        flex-basis: 100%;
    }
}

.u-clear {
    clear:both;
}

// Clear floats like a boss
.u-clearFix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.u-block {
    display: block;
}

.u-inlineBlock {
    display: inline-block;
}

.u-inline {
    display: inline;
}

// Spacer
// Handy spacer class for adding space between items
.u-spacer(@space: 30px; @space-ratio: 2) {
    display: block;
    height: @space;
    width: @space;
    visibility: hidden;

    &--xs {
        @size: @space / (2 * @space-ratio);
        height: @size;
        width: @size;
    }

    &--sm {
        @size: @space / (1 * @space-ratio);
        height: @size;
        width: @size;
    }

    &--lg {
        @size: @space * (@space-ratio * 1);
        height: @size;
        width: @size;
    }

    &--xl {
        @size: @space * (@space-ratio * 2);
        height: @size;
        width: @size;
    }

    &--xxl {
        @size: @space * (@space-ratio * 3);
        height: @size;
        width: @size;
    }
}

hr.spacer {
    border: none;
    margin: 0;
}

// Padding & Margins
@dimensions: top, left, bottom, right;

.u-margin{
    .-(@i: length(@dimensions)) when (@i > 0) {

        @name: extract(@dimensions, @i);

        &-@{name} {
            margin-@{name}: @space;
        }

        &-@{name}-half {
            margin-@{name}: @space / 2;
        }

        &-@{name}-extra {
            margin-@{name}: @space * 1.5;
        }

        &-@{name}-none {
            margin-@{name}: 0!important;
        }

        .-((@i - 1));

    } .-;
}

.u-padding{
    .-(@i: length(@dimensions)) when (@i > 0) {

        @name: extract(@dimensions, @i);

        &-@{name} {
            padding-@{name}: @space;
        }

        .-((@i - 1));

    } .-;
}

.no-margin-b {
    margin-bottom: 0!important;
}