.PageShow {
    &-hero {
        display: flex;
        flex-wrap: wrap;
        .ImageCarousel {
            &-wrap {
                max-width: 100%;
                flex-basis: 100%;
            }
        }

        .BlockList {
            &--featured {
                max-width: 100%;
                flex-basis: 100%;
            }
        }

        &.is-fullWidth {
            .ImageCarousel {
                &-wrap {
                    max-width: 100%;
                    flex-basis: 100%;
                }
            }

            .Title {
                &-wrap {
                    max-width: 100%;
                    flex-basis: 100%;
                }
            }
        }
    }

    &-title {
        margin-bottom: @space*1.5;
    }

    &-content {
        &--title {
            h2 {
                margin-bottom: 0;
            }
        }
    }

    &-img {
        width: 100%;
        &-caption {
            margin-top: 20px;
        }
    }

    &-contact {
        max-width: 100%;
        flex-basis: 100%;
        &-form {
            &-wrap {
                .Form {
                    .Button {
                        min-width: 100%;
                    }
                }
            }
        }

        &-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        &--left {
            padding: @space*1.5 1rem;
            border: solid 1px #c6c6c6;
            background: @lgy-080;
        }

        &--right {
            margin-top: @space*1.5;
            padding-left: 0;
        }

        &-inner {
            margin-bottom: @space*1.5;
        }
    }

    &-main {
        padding-top: @pad-md-tb;
        padding-bottom: @pad-md-tb;
        background: @w-100;
    }

    &-page {
        &-images {
            margin-top: 20px;
            padding: @space / @space-ratio;
        }
    }

    &-images {
        &-image {
            margin-bottom: @space;
        }
    }

    &-blog {
        margin-top: @space;
    }

    &-downloads {
        margin-top: @space;
    }

    @media screen and (min-width: @screen-xs) {
        &-contact {
            &-form {
                &-wrap {
                    .Form {
                        .Button {
                            min-width: 50%;
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        &-hero {
            .ImageCarousel {
                &-wrap {
                    max-width: 63.5%;
                    flex-basis: 63.5%;
                }
            }

            .BlockList {
                &--featured {
                    max-width: 36.5%;
                    flex-basis: 36.5%;
                }
            }
        }

        &-contact {
            max-width: 50%;
            flex-basis: 50%;
            &--left {
                padding: @space*1.5 @pad-md-lr;
            }

            &--right {
                margin-top: 0;
                padding-left: @pad-md-lr;
            }
        }
    }

    @media screen and (min-width: @screen-md) {
        &-hero {
            .ImageCarousel {
                &-wrap {
                    max-width: 64.66%;
                    flex-basis: 64.66%;
                }
            }

            .BlockList {
                &--featured {
                    max-width: 35.33%;
                    flex-basis: 35.33%;
                }
            }

            &.is-fullWidth {
                .ImageCarousel {
                    &-slider {
                        &-img {
                            &-wrap {
                                height: 40vw;
                            }
                        }
                    }
                }
            }
        }
    }
}