.Icon {
	width: calc(~"30px + (45 - 30) * ( (100vw - 320px) / ( 1000 - 320) )");
	height: calc(~"30px + (45 - 30) * ( (100vw - 320px) / ( 1000 - 320) )");
	transform: scale(1);
	transition: @bezier;

	&--w {
		fill: @w-100;
	}

	&--r {
		fill: @rd-100;
	}

	&--bl {
		fill: @bl-100;
	}

	&--dgy {
		fill: @dgy-080;
	}

	@media only screen and (min-width: 1001px) {
        width: 3.10344vw;
		height: 3.10344vw;
    }

	@media only screen and (min-width: @screen-lg) {
        width: @space*1.5;
		height: @space*1.5;
    }
}

.Background {
    &-checked {
    	position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    z-index: 0;
    }
}