.typographyColor(@color; @background: #eee) {
    color: @color;

    p {
        color: @color;
        transition: @bezier;

        strong {
            color: @color;
        }
    }

    h1,h2,h3,h4,h5,h6, .BlockShow-title {
        color: @color;
        transition: @bezier;

        small {
            color: lighten(@color, 80%);
        }
    }

    a {
        color: @color;
        transition: @bezier;
        span {
            &:after {
                background-color: @color;
            }
        }
    }

    .Icon {
        &--w {
            fill: @color;
        }

        // &--r {
        //     fill: @color;
        // }

        &--bl {
            fill: @color;
        }

        &--dgy {
            fill: @color;
        }
    }

    .no-touch {
        .Anchor {
            &:focus,
            &:hover {
                color: @color;
                span {
                    &:after {
                        width: 100%;
                        background-color: @color;
                    }
                }
            }
        }
    }

    .BlockShow {
        &-hr {
            position: relative;
            width: 100%;
            height: 3px;
            margin: @space/3 0 @space;
            border-top: 1px solid @color;
            background: linear-gradient(90deg,rgba(255, 255, 255, 0), @color 50%,rgba(255, 255, 255, 0));
            &:after {
              content: "";
              display: block;
              width: 100%;
              height: 1px;
              background: transparent;
            }
        }
    }
}

.textActiveAnimation() {
    opacity: 1;
    animation-duration: .25s;
    animation-delay: @delay;
    animation-fill-mode: forwards;
}

.textActive(@delay: 0) {
    .textActiveAnimation();
    animation-name: textActive;

    @keyframes textActive {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.textActiveLeftToRight(@delay: 0) {
    //.textActiveAnimation();
    //animation-name: textActiveLeftToRight;
    //
    //@keyframes textActiveLeftToRight {
    //    from {
    //        opacity: 0;
    //        transform: translateX(-10px);
    //    }
    //    to {
    //        opacity: 1;
    //        transform: translateX(0);
    //    }
    //}
}

.textLoading() {
    //opacity: 0;
    //transform: translateY(-10px);
    //-ms-transform: translateY(-10px);
    //-webkit-transform: translateY(-10px);
}
