// TourShow
.TourShow {
    .TourInfo {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto @pad-md-tb auto @pad-md-tb auto @pad-md-tb auto @pad-md-tb auto @pad-md-tb auto @pad-md-tb auto @pad-md-tb auto;
            grid-template-areas:
            "highlights"
            "joining"
            "activity"
            "price"
            "tabs"
            "testimonial"
            // "gallery"
            "book";
        grid-gap: @pad-md-tb 0;
        width: 100%;

        &-intro {
            margin-bottom: @pad-md-tb;
        }

        &-price {
            &-wrap {
                grid-area: price;
                -ms-grid-row: 7;
                -ms-grid-column: 1;
            }

            &-inner {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                padding: @space*1.5 @pad-sm-lr;
                > * {
                    max-width: 100%;
                    flex-basis: 100%;
                }

                &--top {
                    border-left: 1px solid @bl-100;
                    border-right: 1px solid @bl-100;
                    .typographyColor(@w-100);
                    background: @bl-100;
                }

                &--btm {
                    border-left: 1px solid #c6c6c6;
                    border-right: 1px solid #c6c6c6;
                    border-bottom: 1px solid #c6c6c6;
                    color: @dgy-100;
                    background: @lgy-080;
                }

                .Button {
                    width: 100%;
                }
            }

            &-content {
                &--top {
                    span {
                        font-size: @font-size-h4;
                    }
                }

                &--btm {
                    margin-bottom: @space*1.5;
                }

                span {
                    font-family: @font-family-heading;
                }
            }

            &-date {
                display: flex;
                align-items: center;
                padding: @space/2 0;
                border-bottom: 1px solid @dgy-100;
                background: rgba(255,255,255,0.1);
                &:last-child {
                    margin-bottom: 0;
                }

                &-inner {
                    flex: 0 1 90%;
                }

                > .Icon {
                    width: @space;
                    height: @space;
                    margin-right: @space/2;
                    flex: 0 1 10%;
                }

                &-txt {
                    margin-top: @space/2;
                }

                &-note {
                    position: relative;
                    display: block;
                    padding: @space/4 @space/4 @space/4 @space/2;
                    border-left: 2px solid @bl-100;
                    font-weight: 600;
                    line-height: @line-height-heading;
                    color: @rd-100;
                    background: @w-100;

                    &:before {
                        background: none;
                        border-left: 1px solid @rd-100;
                        content: "";
                        display: block;
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        right: 2px;
                        bottom: 2px;
                        pointer-events: none;
                    }
                }
            }

            &-txt {
                margin-bottom: @space/3;
            }

            &-btn {
                &-wrap {
                    &--bl {
                        background-color: @bl-100;
                    }

                    &--rd {
                        background-color: @rd-100;
                    }
                }
            }
        }

        &-testimonial {
            &-wrap {
                grid-area: testimonial;
                -ms-grid-row: 11;
                -ms-grid-column: 1;
                > * {
                    margin-bottom: 0;
                }
            }
        }

        &-related {
            .Tour();
            &-wrap {
                display: none;
                margin-top: 45px;
            }
        }

        &-highlights {
            &-wrap {
                grid-area: highlights;
                -ms-grid-row: 1;
                -ms-grid-column: 1;
                > ul {
                    margin-bottom: 0;
                }
            }
        }

        &-book {
            .box();
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            &-wrap {
                grid-area: book;
                -ms-grid-row: 13;
                -ms-grid-column: 1;
            }

            &--rd {
                background-color: @rd-100;
            }

            &--lgy {
                border: 1px solid #c6c6c6;
                background-color: @lgy-080;
                > .TourInfo-book-txt {
                    color: @font-colour-heading;
                }
            }

            .Button {
                margin-top: @space;
                border: solid 2px @w-100;
                width: 100%;
            }

            &-txt {
                max-width: 100%;
                flex-basis: 100%;
                font-family: @font-family-heading;
            }

            &-tel {
                display: block;
            }
        }

        &-joining {
            &-wrap {
                grid-area: joining;
                -ms-grid-row: 3;
                -ms-grid-column: 1;
            }

            &-inner {
                display: flex;
                margin: 0 -(@space/2);
            }

            &-col {
                padding: @space/2;
            }
        }

        &-activity {
            &-wrap {
                grid-area: activity;
                -ms-grid-row: 5;
                -ms-grid-column: 1;
                position: relative;
            }

            &-level {
                width: 75%;
            }

            &-txt {
                margin-top: @space/2;
            }
        }

        &-tab {
            &-wrap {
                grid-area: tabs;
                -ms-grid-row: 9;
                -ms-grid-column: 1;
                overflow: hidden;
            }
        }

        &-gallery {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            > * {
                padding: 0;
                max-width: 100%;
                flex-basis: 100%;
            }

            > * + * {
                margin-top: @space*1.5;
            }
            

            &-wrap {
                // grid-area: gallery;
                // -ms-grid-row: 13;
                // -ms-grid-column: 1;
            }

            &-title {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-bottom: @space;
                h2 {
                    margin-bottom: 0;
                }
            }

            &-link {
                display: flex;
                margin-left: auto;
                padding: @space/3 0;
                span {
                    color: @rd-100;
                    &:after {
                        background-color: @rd-100;
                    }
                }
            }
        }

        &-btn {
            &-wrap {
                justify-content: flex-end;
                margin-top: @space*1.5;
                &.is-desktop {
                    display: none;
                }

                &.is-mobile, &.is-tabs {
                    display: flex;
                }
            }
        }

        @media screen and (min-width: 415px) {
            &-gallery {
                -ms-grid-columns: 1fr 0 1fr 0 1fr;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: @space*1.5 @pad-md-lr;
            }
        }

        @media screen and (min-width: @screen-sm) {
            -ms-grid-columns: 1fr @pad-md-lr 1fr;
            grid-template-columns: 1fr 1fr;
            -ms-grid-rows: auto @space*1.5 auto @space*1.5 auto @space*1.5 auto @space*1.5 auto @space*1.5 auto @space*1.5 auto;
            grid-template-areas:
                "price highlights"
                "price joining"
                "price activity"
                "price testimonial"
                "tabs tabs"
                // "gallery gallery"
                "book book";
            grid-gap: @space*1.5 @pad-md-lr;
            
            // IE11 fixes 
            &-price {
                &-wrap {
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 7;
                    -ms-grid-column: 1;
                }
            }

            &-testimonial {
                &-wrap {
                    -ms-grid-row: 7;
                    -ms-grid-column: 3;
                }
            }

            &-highlights {
                &-wrap {
                    -ms-grid-row: 1;
                    -ms-grid-column: 3;
                }
            }

            &-book {
                &-wrap {
                    -ms-grid-row: 11;
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 3;
                    // Styles for when gallery is at the bottom
                    // -ms-grid-row: 13;
                    // -ms-grid-column: 1;
                    // -ms-grid-column-span: 3;
                }
            }

            &-joining {
                &-wrap {
                    -ms-grid-row: 3;
                    -ms-grid-column: 3;
                }
            }

            &-activity {
                &-wrap {
                    -ms-grid-row: 5;
                    -ms-grid-column: 3;
                }
            }

            &-tab {
                &-wrap {
                    -ms-grid-row: 9;
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 3;
                }
            }

            &-gallery {
                &-wrap {
                    // -ms-grid-row: 11;
                    // -ms-grid-column: 1;
                    // -ms-grid-column-span: 3;
                }

                margin: -(@space*0.75);
                > * {
                    padding: @space*0.75;
                    max-width: 50%;
                    flex-basis: 50%;
                }

                > * + * {
                    margin-top: 0;
                }
            }
        }

        // @media screen and (min-width: 992px) {
        //     &-gallery {
        //         > * {
        //             max-width: 33.33%;
        //             flex-basis: 33.33%;
        //         }
        //     }
        // }

        @media screen and (min-width: 1200px) {
            -ms-grid-columns: 1fr @pad-md-lr 1fr @pad-md-lr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            -ms-grid-rows: auto @space*1.5 auto @space*1.5 auto @space*1.5 auto @space*1.5 auto;
            grid-template-areas:
                "price  highlights  book"
                "price highlights activity"
                "price joining testimonial"
                "price tabs tabs"
                // "gallery gallery gallery"
                ;
            grid-gap: @space*1.5 @pad-md-lr;
            grid-template-rows: auto auto auto 1fr;

            &-testimonial {
                &-wrap {
                    -ms-grid-row: 5;
                    -ms-grid-column: 5;
                }
            }

            &-related {
                &-wrap {
                    display: flex;
                    flex-wrap: wrap;
                }

                >.Tour {
                    max-width: 100%;
                    flex-basis: 100%;
                    margin-bottom: @space*1.5;
                }
            }

            &-highlights {
                &-wrap {
                    -ms-grid-row: 1;
                    -ms-grid-row-span: 3;
                    -ms-grid-column: 3;
                }
            }

            &-book {
                &-wrap {
                    -ms-grid-row: 1;
                    -ms-grid-column: 5;
                }
            }

            &-joining {
                &-wrap {
                    -ms-grid-row: 5;
                    -ms-grid-column: 3;
                }
            }

            &-activity {
                &-wrap {
                    -ms-grid-row: 3;
                    -ms-grid-column: 5;
                }
            }

            &-tab {
                &-wrap {
                    -ms-grid-row: 7;
                    -ms-grid-column: 3;
                    -ms-grid-column-span: 3;
                }
            }

            &-btn {
                &-wrap {
                    &.is-desktop {
                        display: flex;
                    }

                    &.is-mobile {
                        display: none;
                    }
                }
            }

            // &-gallery {
            //     &-wrap {
            //         -ms-grid-row: 9;
            //         -ms-grid-column: 1;
            //         -ms-grid-column-span: 5;
            //     }
            // }
        }
    }
}