a {
    color: @bl-100;
    transition: @bezier;
    span {
        position: relative;
        font-weight: 600;
        font-family: @font-family-heading;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 0;
            height: 1px;
            background-color: @bl-100;
            transition: @bezier;
        }
    }
}

.no-touch {
    a {
        &:focus,
        &:hover {
            span {
                &:after {
                    width: 100%;
                }
            }
        }
    }

    .MenuMain {
        &-item {
            &-link {
                &:focus,
                &:hover {
                    color: @bl-100;
                    span {
                        &:after {
                            width: 100%;
                        }
                    }
                }

                &--phone {
                    &:focus,
                    &:hover {
                        color: @rd-100;
                    }
                }
            }
        }
    }
}

p {
    margin: 0;
    padding: 0;
    line-height: @line-height;

    small {
        color: lighten(@font-colour, 10%);
    }

    strong {
        font-weight: 700;
    }

}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1.5rem;
    padding: 0;
    font-family: @font-family-heading;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: @font-colour-heading;
    &:first-child {
        margin-top: 0;
    }

    small {
        color: lighten(@font-colour-heading, 10%);
        display: inline-block;
        &:before {
            content: ' ';
        }
    }

    > a, a > & {
        color: @font-colour-link;
        text-decoration: none;
    }
}

h1 {
  font-size: @font-size-h2;
  line-height: @font-size-h2;
  margin-top: 0;
}

h2 {
  font-size: @font-size-h3;
  line-height: @font-size-h3;
  margin-top: calc((1.5rem - @font-size-h3) + 1.5rem*1.5);
}

h3 {
  font-size: @font-size-h4;
  line-height: @font-size-h4;
  margin-top: calc((1.5rem - @font-size-h4) + 1.5rem*1.5);
}

h4 {
  font-size: @font-size-h5;
  line-height: @font-size-h5;
  margin-top: calc((1.5rem - @font-size-h5) + 1.5rem*1.5);
}

h5, h6 {
  font-size: @font-size-h6;
  line-height: @font-size-h6;
  margin-top: calc((1.5rem - @font-size-h6) + 1.5rem*1.5);
}

@media screen and (min-width: 1025px) {
    h1 {
        font-size: @font-size-h1;
        line-height: @font-size-h1;
        margin-top: 0;
    }

    h2 {
        font-size: @font-size-h2;
        line-height: @font-size-h2;
        margin-top: calc((1.5rem - @font-size-h2) + 1.5rem*1.5);
    }

    h3 {
        font-size: @font-size-h3;
        line-height: @font-size-h3;
        margin-top: calc((1.5rem - @font-size-h3) + 1.5rem*1.5);
    }

    h4 {
        font-size: @font-size-h4;
        line-height: @font-size-h4;
        margin-top: calc((1.5rem - @font-size-h4) + 1.5rem*1.5);
    }
}

blockquote {
    position: relative;
    margin: 0 0 @space*1.5 0;
    padding-left: @pad-sm-lr;
    border-left: 2px solid @bl-100;
    font-family: @font-family-heading;
    font-size: @font-size-h5;
    font-weight: 500;
    line-height: @line-height-heading;
    color: @dgy-100;
    &:before {
        background: none;
        border-left: 1px solid @rd-100;
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        pointer-events: none;
    }

    @media screen and (min-width: @screen-md) {
        font-size: @font-size-h4;
    }
}

cite {
    display: block;
    margin-top: @space/2;
    font-family: @font-family;
    font-size: @font-size;
    font-style: normal;
}

ul, ol {
    margin: 0;
    padding-left: calc(~"30px + (60 - 30) * ( (100vw - 320px) / ( 1920 - 320) )");
    li {
        margin-bottom: 5px;
    }
}

img {
	border: none;
	max-width: 100%;
	height: auto;
}

hr {
    border: none;
    border-top: 1px solid #c6c6c6;
    margin: @space 0;
}

.Booking-hr {
    position: relative;
    margin: @space 0;
    height: 3px;
    border-top: 1px solid @rd-100;
    background: linear-gradient(90deg,rgba(0, 35, 156, 0), @bl-100 50%,rgba(0, 35, 156, 0));
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: @w-100;
    }
}

table {
	.table();
	.table-striped();
}
