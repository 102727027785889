.PageShow--fullWidth {
    .PageShow {
        &-gallery {
        	&-wrap {
				display: flex;
				flex-wrap: wrap;
				margin: 0;
		        > * {
		            padding: 0;
		            max-width: 100%;
		            flex-basis: 100%;
		        }

		        > * + * {
		            margin-top: @space*1.5;
		        }
        	}
        }

        @media screen and (min-width: @screen-sm) {
	        &-gallery{
	        	&-wrap {
		            margin: -(@space*0.75);
		            > * {
		                padding: @space*0.75;
		                max-width: 50%;
		                flex-basis: 50%;
		            }

		            > * + * {
		                margin-top: 0;
		            }
		        }
	        }
	    }

	    @media screen and (min-width: 992px) {
	        &-gallery {
	        	&-wrap {
		            > * {
		                max-width: 33.33%;
		                flex-basis: 33.33%;
		            }
		        }
	        }
	    }

	    @media screen and (min-width: 1200px) {
	        &-gallery {
	        	&-wrap {
		            margin: -(@space);
		            > * {
		                padding: @space;
		            }
		        }
	        }
	    }
    }
}
