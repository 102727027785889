
.u-content {
    a {
        position: relative;
        text-decoration: none;
        color: @bl-100;
        transition: @bezier;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 1px;
            background-color: @bl-100;
            transition: @bezier;
        }

        @media screen and (min-width: @screen-md) {
            &:hover {
                color: @rd-100;
                &:after {
                    background-color: @rd-100;
                }
            }
        }
    }

    p {
        margin-bottom: 1.5rem;
        small {
            margin-bottom: 1.25em;
        }

        b, strong {
            font-weight: 700;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        display: flex;
        align-items: center;
        img {
            margin-right: @pad-xs-lr;
        }

        b, strong {
            font-weight: 500;
        }
    }

    p {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul, ol {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: @screen-xs-max) {
    table {
        margin-bottom: 22px;
        display: block;
        width: 100%;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

ol {
    padding-left: @space*1.5;
}

.bullist {
    display: inline-block;
    margin-bottom: 20px;
    padding-left: @space*1.5;
    list-style: none;

    li {
        clear:left;
        margin-bottom: 0.5em;
        &:before {
            content: "";
            height: @space;
            width: @space;
            display: block;
            float: left;
            margin-left: -(@space*1.5);
            background-image: url('/assets/img/difficulty/SVG/logoBike.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.u-content--positive {
    .typographyColor(@w-100);
}

.u-content--negative {
    .typographyColor(@dgy-100);
}

.u-content--lg {
    font-size: 1.3em;
}

.u-content--sm {
    font-size: 0.9em;
}

.u-content--xs {
    font-size: 0.8em;
}