.ImageList {
    a {
        margin-top: 15px;
        display: block;

        &:first-of-type {
            margin-top: 0;
        }

        &-image {

        }
    }
}