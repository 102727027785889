.Tab {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: left;
    flex-wrap: wrap;
    list-style: none;
    border-top: 1px solid #c6c6c6;
    border-left: 1px solid #c6c6c6;
    > * + * {
        border-right: 1px solid #c6c6c6;
        border-bottom: 1px solid #c6c6c6;
    }

    &-btn {
        flex: 1 1 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 60px;
        margin: 0;
        padding: @space/2;
        font-family: @font-family-heading;
        font-weight: 500;
        text-align: center;
        color: @bl-100;
        transition: @bezier;
        &:first-child {
            border-right: 1px solid #c6c6c6;
            border-bottom: 1px solid #c6c6c6;
        }

        .Icon {
            width: 30px;
            height: 30px;
        }

        &:hover {
            border: solid 1px @bl-100;
            color: @w-100;
            background: @bl-100;
            .Icon {
                &--bl {
                    fill: @w-100;
                }
            }
        }
    }

    &-content {
        padding: @space*1.5 @pad-sm-lr;
        border-left: 1px solid #c6c6c6;
        border-right: 1px solid #c6c6c6;
        border-bottom: 1px solid #c6c6c6;
        background: @lgy-080;
        outline: none;
        text-shadow: none;
        transition: @bezier;
        animation: fadeEffect 1s;

        > * {
            &:focus {
                outline: 1px solid transparent;
            }
        }
    }

    &-title {
        margin-bottom: 0;
        color: @bl-100;
    }

    &-hr {
        position: relative;
        width: 100%;
        height: 3px;
        margin: @space/2 0 @space*0.66;
        border-top: 1px solid @rd-100;
        background: linear-gradient(90deg,rgba(0, 35, 156, 0), @bl-100 50%,rgba(0, 35, 156, 0));
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: @w-100;
        }
    }

    @media screen and (min-width: 415px) {
        &-btn {
            flex: 1 1 50%;
            justify-content: center;
        }
    }

    @media screen and (min-width: 850px) {
        &-btn {
            flex: 1 1 25%;
        }
    }

    @media screen and (min-width: 1200px) {
        &-content {
            min-height: 1200px;
        }
    }
}

[role="tablist"] [aria-selected]:focus,
[role="tablist"] [aria-selected="true"] {
    border: 1px solid @bl-100;
    color: @w-100;
    background-color: @bl-100;
    transition: @bezier;
    .Icon {
        &--bl {
            fill: @w-100;
        }
    }
}

[role="tabpanel"][aria-hidden="true"] {
    display: none;
}

@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}