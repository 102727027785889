.Error {
	.Button {
		width: 100%;
		margin-top: @space/2;
	}

	@media screen and (min-width: 320px) {
		.Button {
			margin-top: @pad-sm-lr;
		}
	}

	@media screen and (min-width: @screen-xs) {
		.Button {
			width: auto
		}
	}

	@media screen and (min-width: 1920px) {
		.Button {
			margin-top: @space*1.5;
		}
	}
}