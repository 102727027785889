.ShowGuide {
    margin: @space 0;

    &-image {
        padding: 15px 32px;

        img {
            border-radius: 50%;
            overflow: hidden;
        }
    }

    &-content {
        padding-right: 32px;
    }
}
