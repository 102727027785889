// TourDateIndex
.TourDateIndex {
    &-wrap {
        padding-bottom: @pad-md-tb;
        &:last-child {
            padding-bottom: 0;
        }

        h2 {
            margin-bottom: @space;
            padding: @space/2;
            background: linear-gradient(135deg,rgba(248,248,248,1) 60%,rgba(248,248,248,0) 90%);
        }
    }

    &-date {
        .Tour();
        &-wrap {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            > * {
                padding: 0;
                max-width: 100%;
                flex-basis: 100%;
            }

            > * + * {
                margin-top: @space*1.5;
            }
        }
    }

    &-hr {
        position: relative;
        width: 100%;
        height: 3px;
        margin: 0;
        border-top: 1px solid @rd-100;
        background: linear-gradient(90deg,rgba(0, 35, 156, 0), @bl-100 50%,rgba(0, 35, 156, 0));
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: @w-100;
        }

        &:last-child {
            margin: 0;
        }
    }

    @media screen and (min-width: @screen-sm) {
        &-wrap {
            h2 {
                margin-bottom: @space*1.5;
                padding: @space*0.75;
            }
        }

        &-date {
            &-wrap {
                margin: -(@space*0.75);
                > * {
                    padding: @space*0.75;
                    max-width: 50%;
                    flex-basis: 50%;
                }

                > * + * {
                    margin-top: 0;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        &-date {
            &-wrap {
                > * {
                    max-width: 33.33%;
                    flex-basis: 33.33%;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) {
        &-wrap {
            h2 {
                margin-bottom: @space*2;
                padding: @space;
            }
        }

        &-date {
            &-wrap {
                margin: -(@space);
                > * {
                    padding: @space;
                }
            }
        }
    }
}